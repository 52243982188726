import React, { Component } from "react";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";
import { Dimensions } from "react-native";

// import DevicePolicy from "./OpenUrls/DevicePolicy";
// import PostApproval from "./Authed/ContentCalendar/PostApproval";
import PerformanceReview from "./OpenUrls/PerformanceReview/PerformanceReview";

// import Home from "./Authed/Home";
import Login from "./Unauthed/Login";
import InfluencerSignupForm from "./OpenUrls/InfluencerSignup";
// import HomeMobile from "./Authed/HomeMobile";
// import CompanyDocuments from "./OpenUrls/CompanyDocuments";
// import LiveStream from "./OpenUrls/STREAM";
// import ContentApproval from "./Authed/ContentCalendar/ContentApproval";

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

if (typeof window !== "undefined") {
	require("smoothscroll-polyfill").polyfill();
}

class index extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
			console.log("Polly fill for smooth scroll in safari");
		} else {
			// production code
		}

		this.resize();
	}

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	render() {
		return (
			<Router>
				<Routes>
					<Route path='/login' element={<Login />} />
					{/* <Route path='/Home' element={<Home />} /> */}

					<Route
						path='/signup/:brand'
						element={<InfluencerSignupForm />}
					/>

					{/* <Route
						path='/smreport/:brand/:token'
						element={<PerformanceReview />}
					/> */}

					{/* <Route path='/newmember/:company' element={<LiveStream />} /> */}

					{/* <Route
						path='/postapproval/:postid/:token'
						element={<PostApproval />}
					/>

					<Route
						path='/caa/:contentid/:token'
						element={<ContentApproval />}
					/>

					<Route
						path='/performancereview/:surveyId/:token'
						element={<PerformanceReview />}
					/>

					<Route path='/livestream/:token' element={<LiveStream />} />

				

					<Route
						path='/smreport/:brand/:token'
						element={<PerformanceReview />}
					/>

					<Route
						path='/doc/:documentId/:token'
						element={<CompanyDocuments />}
					/> */}

					<Route path='*' element={<Navigate to='/login' />} />
				</Routes>
			</Router>
		);
	}
}

export default index;
