import React, { useState, useEffect } from "react";
import {
	View,
	Text,
	TextInput,
	StyleSheet,
	TouchableOpacity,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
import AutoHeightImage from "../../Components/AutoHeightImageComp";

const CustomRadioButton = ({ isChecked, color }) => {
	return (
		<View
			style={{
				height: 24,
				width: 24,
				borderRadius: 12,
				// borderWidth: 1,
				borderColor: color,
				alignItems: "center",
				justifyContent: "center",
				// backgroundColor: isChecked ? color : "white",
			}}>
			<AutoHeightImage
				width={isChecked ? 20 : 0}
				source={require("./verified.png")}
			/>
		</View>
	);
};

function PersonalDetailsForm({ onDetailsChange }) {
	const [gender, setGender] = useState(null);
	const [race, setRace] = useState(null);
	const [age, setAge] = useState("");

	const genderOptions = ["Male", "Female", "Other", "Not specified"];
	const raceOptions = [
		"Asian",
		"Ethnic",
		"White",
		"Indian",
		"Mixed",
		"Other",
	];

	// Listen to changes on gender, race, and age
	useEffect(() => {
		if (gender !== null && race !== null && age !== null) {
			onDetailsChange({ gender, race, age });
		}
	}, [gender, race, age]);

	return (
		<View style={styles.container}>
			<Text style={styles.label}>Age</Text>
			<TextInput
				style={styles.input}
				onChangeText={(text) => {
					setAge(text);
				}}
				value={age}
				keyboardType='numeric'
			/>

			<Text style={styles.label}>Gender</Text>
			{genderOptions.map((option) => (
				<TouchableOpacity
					key={option}
					onPress={() => {
						setGender(option);
					}}
					style={[
						styles.optionContainer,
						{
							backgroundColor: "#FFF",
							// 	gender === option
							// 		? APP_COLOURS.DESELECTEDCOLOR
							// 		: "#FFF",
						},
					]}>
					<Text
						style={[
							styles.optionText,
							{
								color:
									gender === option
										? APP_COLOURS.BLUE
										: "#000",
							},
						]}>
						{option}
					</Text>
					<CustomRadioButton
						isChecked={gender === option}
						color={APP_COLOURS.BLUE}
					/>
				</TouchableOpacity>
			))}

			<Text style={styles.label}>Race</Text>
			{raceOptions.map((option) => (
				<TouchableOpacity
					key={option}
					onPress={() => {
						setRace(option);
					}}
					style={styles.optionContainer}>
					<Text
						style={[
							styles.optionText,
							{
								color:
									race === option ? APP_COLOURS.BLUE : "#000",
							},
						]}>
						{option}
					</Text>
					<CustomRadioButton
						isChecked={race === option}
						color={APP_COLOURS.BLUE}
					/>
				</TouchableOpacity>
			))}
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		// flex: 1,
		paddingTop: 22,
	},
	label: {
		fontSize: 20,
		fontWeight: "bold",
		marginTop: 20,
		color: APP_COLOURS.BG3,
	},
	input: {
		height: 40,
		borderColor: "gray",
		borderWidth: 1,
		marginTop: 10,
		padding: 10,
		backgroundColor: "#FFF",
		borderRadius: 10,
		fontSize: 19,
		fontWeight: "600",
	},
	optionContainer: {
		flexDirection: "row",
		alignItems: "center",
		marginBottom: 5,
		justifyContent: "space-between",
		padding: 5,
		marginTop: 2,
		borderWidth: 0.5,
		borderRadius: 10,
		borderColor: APP_COLOURS.BACK_TRANSPARENT2,
		backgroundColor: "#FFF",
	},
	optionText: {
		marginLeft: 10,
		fontSize: 18,
	},
});

export default PersonalDetailsForm;
