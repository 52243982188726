let e = "https://us-central1-prostaff-ba627.cloudfunctions.net/app";
//

export const api_opensignup = e + "/open/signup";

//
//
//
//
//
//
export const api_login = e + "/pm/login";

export const api = e;

export const api_crudCreate = api + "/pm/c";
export const api_crudRead = api + "/pm/r";

export const api_crudReadUnass = api + "/pm/runass";

export const api_crudUpdate = api + "/pm/u";
export const api_crudDelete = api + "/pm/d";
export const api_crudWithImage = api + "/pm/cwimg";
export const api_crudDeleteDocument = api + "/pm/cdelimg";
export const api_sendBasicEmail = api + "/pm/sendBasicEmail";
export const api_sendDiscordMessage = api + "/pm/sendDiscordMessage";

export const api_getUsers = api + "/pm/users";
export const api_createPostApproval = api + "/pm/createPostApproval";
export const api_creatSurveyFeedback = api + "/pm/creatSurveyFeedback";

//
export const api_contentApprovalRequest = api + "/pm/contentapprovalopen";
