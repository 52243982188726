import React, { useState } from "react";
import {
	View,
	Text,
	Modal,
	TouchableOpacity,
	Image,
	FlatList,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";

const MultiSelectComponent = ({ onSelectionChange }) => {
	const [modalVisible, setModalVisible] = useState(false);
	const [selectedItems, setSelectedItems] = useState([]);

	const interests = [
		"Product Reviews",
		"Brand Ambassador",
		"Event Appearances",
		"Sponsored Posts",
		"Social Media Mentions",
		"Giveaways",
		"Tutorials/How-Tos",
		"Unboxing Videos",
		"Post Collaborations",
		"Product photography",
		"Content creation",
	];

	const handleSelect = (item) => {
		setSelectedItems((currentSelectedItems) => {
			let newSelectedItems;
			if (currentSelectedItems.includes(item)) {
				newSelectedItems = currentSelectedItems.filter(
					(i) => i !== item,
				);
			} else {
				newSelectedItems = [...currentSelectedItems, item];
			}
			// Call the callback function here after state is updated
			onSelectionChange(newSelectedItems);
			return newSelectedItems;
		});
	};

	const renderItem = ({ item }) => (
		<TouchableOpacity
			style={{ padding: 10, flexDirection: "row", alignItems: "center" }}
			onPress={() => handleSelect(item)}>
			<Image
				source={require("../assets/images/tick.png")}
				style={{
					width: 20,
					height: 20,
					resizeMode: "contain",
					marginRight: 10,
					opacity: selectedItems.includes(item) ? 1 : 0,
				}}
			/>
			<Text style={{ fontSize: 16, color: "#1F2229" }}>{item}</Text>
		</TouchableOpacity>
	);

	return (
		<View style={{}}>
			<TouchableOpacity
				style={{
					width: "100%",
					padding: 10,
					justifyContent: "center",
					alignItems: "center",
					minWidth: 300,
					marginTop: 15,
					borderWidth: 1,
					borderColor: "#1F2229",
					backgroundColor: "#FFF",
					borderRadius: 12,
					flexDirection: "row",
				}}
				onPress={() => setModalVisible(true)}>
				<Text
					style={{
						fontSize: 16,
						padding: 10,
						color:
							selectedItems.length > 0
								? "#1F2229"
								: APP_COLOURS.BLUE2,
					}}>
					{selectedItems.length > 0
						? selectedItems.join(", ")
						: "Select your interests"}
				</Text>
			</TouchableOpacity>

			<Modal
				animationType='slide'
				transparent={true}
				visible={modalVisible}
				onRequestClose={() => setModalVisible(false)}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",

						backgroundColor: APP_COLOURS.BACK_TRANSPARENT2,
					}}>
					<View
						style={{
							backgroundColor: "white",
							padding: 20,
							borderRadius: 12,
							elevation: 5,
						}}>
						<Text
							style={{
								fontSize: 16,
								color: "#1F2229",
								marginBottom: 10,
								fontWeight: "500",
							}}>
							Select your interests
						</Text>
						<FlatList
							data={interests}
							renderItem={renderItem}
							keyExtractor={(item) => item}
						/>
						<TouchableOpacity
							style={{
								marginTop: 10,
								padding: 10,
								backgroundColor: "#007bff",
								borderRadius: 10,
							}}
							onPress={() => setModalVisible(false)}>
							<Text
								style={{ color: "white", textAlign: "center" }}>
								Done
							</Text>
						</TouchableOpacity>
					</View>
				</View>
			</Modal>
		</View>
	);
};

export default MultiSelectComponent;
