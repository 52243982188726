import React, { useState } from "react";

const ProfileImageUploader = ({ onFileChange }) => {
	const [imageBase64, setImageBase64] = useState("");
	const [errorMessage, setErrorMessage] = useState("");

	const handleImageChange = (event) => {
		setErrorMessage("");
		const file = event.target.files[0];

		if (!file) return;

		//  if (file.type !== 'image/png') {
		//    setErrorMessage('Invalid file type. Please upload a PNG image.');
		//    return;
		//  }

		if (!file.type.startsWith("image/")) {
			setErrorMessage("Please upload an image file.");
			return;
		}

		const reader = new FileReader();
		// reader.onloadend = () => {
		// 	const img = new Image();
		// 	img.onload = () => {
		// 		const canvas = document.createElement("canvas");
		// 		let ctx = canvas.getContext("2d");

		// 		// Start with the original image dimensions
		// 		let width = img.width;
		// 		let height = img.height;

		// 		// If the image size is greater than 2MB, reduce the dimensions
		// 		if (file.size > 500 * 1024) {
		// 			// Calculate the new dimensions to maintain the aspect ratio
		// 			const scaleFactor = Math.sqrt((500 * 1024) / file.size);
		// 			width = width * scaleFactor;
		// 			height = height * scaleFactor;
		// 		}

		// 		// Set the canvas dimensions to the new size
		// 		canvas.width = width;
		// 		canvas.height = height;

		// 		// Draw the image at the new dimensions
		// 		ctx.drawImage(img, 0, 0, width, height);

		// 		// Convert the canvas to a PNG data URL
		// 		canvas.toBlob((blob) => {
		// 			const newFile = new File([blob], file.name, {
		// 				type: file.type,
		// 				lastModified: Date.now(),
		// 			});

		// 			// Update the image base64 state with the reduced image
		// 			const reader = new FileReader();
		// 			reader.onloadend = () => {
		//             setImageBase64(reader.result);

		//             return {
		//                name: file.name,
		//                file_name: file.name,
		//                size: file.size,
		//                type: file.type,
		//                doc: file.type,
		//                base64: reader.result,
		//             };
		// 				setParentState(reader.result);
		// 			};
		// 			reader.readAsDataURL(newFile);
		// 		}, "image/png");
		// 	};
		// 	img.src = reader.result;
		// };

		reader.onloadend = () => {
			const img = new Image();
			img.onload = () => {
				const canvas = document.createElement("canvas");
				const ctx = canvas.getContext("2d");

				// Start with the original image dimensions
				let width = img.width;
				let height = img.height;

				// If the image size is greater than 500KB, reduce the dimensions
				if (file.size > 500 * 1024) {
					// Calculate the new dimensions to maintain the aspect ratio
					const scaleFactor = Math.sqrt((500 * 1024) / file.size);
					width = width * scaleFactor;
					height = height * scaleFactor;
				}

				// Set the canvas dimensions to the new size
				canvas.width = width;
				canvas.height = height;

				// Draw the image at the new dimensions
				ctx.drawImage(img, 0, 0, width, height);

				// Convert the canvas to a data URL
				canvas.toBlob((blob) => {
					const newFile = new File([blob], file.name, {
						type: file.type, // Use the original file type
						lastModified: Date.now(),
					});

					const reader = new FileReader();
					reader.onloadend = () => {
						// Call the parent callback function with all file information
						onFileChange({
							name: newFile.name,
							size: newFile.size,
							type: newFile.type,
							base64: reader.result,
						});

						setImageBase64(reader.result);
					};
					reader.readAsDataURL(newFile);
				}, file.type); // Use the original file type
			};
			img.src = reader.result;
		};
		reader.readAsDataURL(file);
	};

	return (
		<div
			style={{
				minWidth: 300,
				marginTop: 15,
				borderWidth: 1,
				borderColor: "#1F2229",
				backgroundColor: "#FFF",
				borderRadius: 12,
				flexDirection: "row",
				padding: 15,
				justifyContent: "center",
				alignItems: "center",
			}}>
			<input type='file' onChange={handleImageChange} />
			{errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
			{imageBase64 && (
				<img
					src={imageBase64}
					alt='Profile'
					style={{
						maxWidth: "100%",
						maxHeight: "100%",
						borderRadius: 12,
					}}
				/>
			)}
		</div>
	);
};

export default ProfileImageUploader;
