import moment from "moment";
import {
	api_crudCreate,
	api_crudRead,
	api_crudDelete,
	api_crudUpdate,
	api_getUsers,
	api_crudWithImage,
	api_crudDeleteDocument,
	api_sendBasicEmail,
	api_createPostApproval,
	api_crudReadUnass,
	api_creatSurveyFeedback,
	api_sendDiscordMessage,
	api_opensignup,
} from "./Api";

// THIS FILE IS to get and set async data of the device without making too many requests

export const crudCreate = async (collection, where, next) => {
	let url = api_crudCreate;
	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			data: where,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);
	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status);
					});
			} else {
				logOut();
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudCreateWithBase64 = async (collection, where, next) => {
	let url = api_crudWithImage;
	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			data: where,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);

	// console.log({
	//   collection: collection,
	//   data: where,
	// });

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status);
					});
			} else {
				logOut();
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudDeleteDocument = async (collection, where, next) => {
	let url = api_crudDeleteDocument;
	let user_data = await getUserData();
	let requestOptions = {
		method: "DELETE",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			data: where,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status);
					});
			} else {
				logOut();
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudDeleteArray = async (collection, where, next) => {
	let url = api_crudDeleteDocument;
	let user_data = await getUserData();
	let requestOptions = {
		method: "DELETE",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			data: where,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status);
					});
			} else {
				logOut();
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudRead = async (collection, where, next) => {
	let url = api_crudRead;
	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			where: where,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);
	await fetch(url, requestOptions)
		.then((response) => {
			console.log("crudRead", response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status, []);
					});
			} else {
				logOut();
				return next(response.status, []);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudReadUnass = async (collection, where, next) => {
	let url = api_crudReadUnass;
	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			where: where,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);
	await fetch(url, requestOptions)
		.then((response) => {
			console.log("crudRead", response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status, []);
					});
			} else {
				logOut();
				return next(response.status, []);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudReadAll = async (collection, next) => {
	let url = api_crudRead;
	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			where: "",
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);
	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status);
					});
			} else {
				logOut();
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudUpdate = async (collection, obj, next) => {
	let url = api_crudUpdate;
	let user_data = await getUserData();
	let requestOptions = {
		method: "PUT",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			data: obj,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);
	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				console.log("result ");
				return next(200);
			} else if (response.status === 401) {
				logOut();
			} else {
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudDelete = async (collection, obj, next) => {
	let url = api_crudDelete;
	let user_data = await getUserData();
	let requestOptions = {
		method: "DELETE",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			data: obj,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);
	await fetch(url, requestOptions)
		.then((response) => {
			if (response.status !== 200) {
				logOut();
				return;
			} else {
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

//

export const crudSendEmail = async (sender, recipient, subject, body) => {
	var myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");

	let url = api_sendBasicEmail;

	var raw = JSON.stringify({
		jwt: "dnYESWgXMYn5WVgdUjREDqvuzezfDn9WuYQRDNIFNggEVYGNVEu",
		sender: sender,
		recipient: recipient,
		body: `${body}`,
		subject: `${subject}`,
	});

	var requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		redirect: "follow",
	};
	// console.log(url, requestOptions);
	// return;
	fetch(url, requestOptions)
		.then((response) => {
			console.log(response.status);
			if (response.status !== 200) {
				alert("Error sending email!");
			} else if (response.status === 200) {
				// alert("Sent!");

				return;
			}
		})
		.catch((error) => console.log("error", error));
};

export const sendDiscordMessage = async (username, message, avatar_url, id) => {
	var myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");

	let url = api_sendDiscordMessage;

	var raw = JSON.stringify({
		jwt: "dnYESWgXMYn5WVgdUjREDqvuzezfDn9WuYQRDNIFNggEVYGNVEu",
		message: message,
		username: username,
		avatar_url: avatar_url,
		id: id,
	});

	var requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		redirect: "follow",
	};
	// console.log(url, requestOptions);
	// return;
	fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status !== 200) {
				// alert("Error updating email!");
				// logOut();
			} else if (response.status === 200) {
				// alert("Sent!");

				return;
			}
		})
		.catch((error) => console.log("error", error));
};

//
//

async function getUserData() {
	try {
		const value = await sessionStorage.getItem("user_data");
		let parse = JSON.parse(value);
		if (value !== null) {
			return parse;
		} else {
			return;
		}
	} catch (error) {
		return;
	}
}

async function removeAsyncItem(key) {
	try {
		await sessionStorage.removeItem(key);
		return true;
	} catch (exception) {
		alert("Error removing item");
		return false;
	}
}

// async function onValueChange(item, selectedValue) {
// 	try {
// 		await sessionStorage.setItem(item, selectedValue).then((x) => {
// 			// console.log('onValueChange', item, selectedValue);
// 		});
// 	} catch (error) {
// 		console.error(error);
// 	}
// }

export const createPostApproval = async (data, next) => {
	await console.log("createPostApproval");

	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${data.token}`,
		},

		redirect: "follow",
		body: JSON.stringify(data),
	};

	// console.log(data.token);
	// return;
	fetch(api_createPostApproval, requestOptions)
		.then((response) => {
			if (response.status == 200) {
				response
					.json()
					.then(async (r) => {
						return next(response.status);
					})
					.catch((error) => {
						return next(response.status);
					});
			} else {
				return next(response.status);
			}
		})
		.catch((error) => {
			console.log(error);
			return next(500);
		});
};

export const createAmbassadorSignup = async (data, next) => {
	// await console.log("createSurveyApproval");
	// alert("No API set up");

	let token = "xV3-0Ea5T2eLbRJYo-eGoT0Dq_Lq9o1-kyGK5gg";

	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${token}`,
		},

		redirect: "follow",
		body: JSON.stringify(data),
	};

	// console.log(data.token);
	// return;
	fetch(api_opensignup, requestOptions)
		.then((response) => {
			if (response.status == 200) {
				response
					.json()
					.then(async (r) => {
						return next(response.status);
					})
					.catch((error) => {
						return next(response.status);
					});
			} else {
				return next(response.status);
			}
		})
		.catch((error) => {
			console.log(error);
			return next(500);
		});
};
//
//
//
//
export const crudGetUsers = async (next) => {
	await console.log("crudGetUsers");
	const responseData = await getUsers((d) => {
		// console.log('response =====================', d);
		return next(d);
	});
};
async function getUsers(next) {
	let user_data = await getUserData();
	let requestOptions = {
		method: "GET",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},

		redirect: "follow",
	};
	fetch(api_getUsers, requestOptions)
		.then((response) => {
			if (response.status == 200) {
				response
					.json()
					.then(async (r) => {
						// const set_ = await onValueChange(
						// 	"users",
						// 	,
						// );
						sessionStorage.setItem("users", JSON.stringify(r));
						return next(r);
					})
					.catch((error) => {
						return [];
					});
			} else {
				console.log(response);
				return next([]);
			}
		})
		.catch((error) => {
			console.log(error);
			return next([]);
		});
}

async function logOut() {
	sessionStorage.clear();
	window.location.href = "/";
}
