import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import { useNavigate } from "react-router-dom";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import moment from "moment";
import tick from "./tick_red.png";
import "./index.css";
import { api_login } from "../Api";
import * as animationData from "./animation_lnu500l9.json";
import { crudUpdate } from "../CRUDHELPER";
import { APP_COLOURS } from "../APP_VARS";
import AutoHeightImage from "../Components/AutoHeightImageComp";

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

class index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			password: "",
			emailInput: "",
			emailValid: false,
			screenWidth: screenWidth,
			screenHeight: screenHeight,

			otpPinSent: false,

			showLoginForm: false,
		};
	}

	validateEmail = (text) => {
		// console.log(text);
		let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if (reg.test(text) === false) {
			// console.log("Email is Not Correct");
			this.setState({ emailValid: false });
			return false;
		} else {
			this.setState({ emailValid: true });
			// console.log("Email is Correct");
			return true;
		}
	};

	loginButton = async () => {
		this.setState({
			loading: true,
			otpPinSent: true,
			errorLogin: false,
		});
		await sessionStorage.setItem("email", this.state.emailInput);
		// await sessionStorage.setItem('password', this.state.password);
	};

	loginButton2 = async () => {
		this.setState({
			loading: true,
			// otpPinSent: true,
			errorLogin: false,
		});

		await sessionStorage.setItem("email", this.state.emailInput);
		// await sessionStorage.setItem('password', this.state.password);
		// this.props.history.push("/dashboard/");
		// setTimeout(() => this.checkRSVP(), 3000);
		// this.loginFunc();
		this.loginFuncWithOTP();
	};

	loginFuncWithOTP = async () => {
		let url = api_login;
		let password = this.state.password;
		let email = this.state.emailInput;

		var raw = JSON.stringify({
			passcode: password,
			username: email,
		});

		var requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: raw,
		};

		console.log(url, requestOptions);

		await fetch(url, requestOptions)
			.then((response) => {
				// console.log("response.status", response.status);
				console.log("response =====", response);
				if (response.status == 200) {
					response
						.json()
						.then(async (responseData) => {
							// await console.log(
							// 	"=============================",
							// 	responseData,
							// );
							let user_data = await sessionStorage.setItem(
								"user_data",
								JSON.stringify(responseData),
							);
							let accessToken = await sessionStorage.setItem(
								"accessToken",
								responseData.accessToken,
							);

							let now = moment().toISOString();
							const lastUpdated = await sessionStorage.setItem(
								"lastUpdated",
								now,
							);

							crudUpdate(
								"users",
								{
									id: responseData.id,
									status: "Online",
									lastSeen: now,
									location: this.state.location,
									statusDescription: "Online",
								},
								(status, d) => {},
							);

							await this.props.navigate("/Home");
						})
						.catch((error) => {
							alert(error);
							this.setState({
								loading: false,
								password: "",
								emailInput: "",
								emailValid: false,
								otpPinSent: false,
							});
						});
				} else {
					// alert("User has not been found");
					this.setState({
						errorLogin: true,
						loading: false,
						password: "",
						emailInput: "",
						emailValid: false,
						otpPinSent: false,
					});
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					errorLogin: true,
					loading: false,
					password: "",
					emailInput: "",
					emailValid: false,
					otpPinSent: false,
				});
			});
	};

	componentDidMount() {
		console.log("componentDidMount");
		let accessToken = sessionStorage.getItem("accessToken");

		console.log("Approved User", accessToken);
		if (accessToken !== null) {
			// this.props.history.push("/dashboard/Home");
		} else {
			sessionStorage.clear();
			this.setState({
				loading: false,
			});
		}

		window.addEventListener("resize", this.resize());
		// this.resize();

		// setTimeout(
		// 	() =>
		this.setState({
			showLoginForm: true,
		});
		// 	2000,
		// );
	}

	componentDidUpdate() {}

	resize() {
		// alert("screen rotate");

		setTimeout(() => {
			this.setState({
				screenHeight: window.innerHeight,
				screenWidth: window.innerWidth,
			});
		}, 300);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));
	}

	loginForm = () => {
		return (
			<View
				style={{
					flex: 2,
					width: "100%",
					alignItems: "center",
					// justifyContent: 'center',
				}}>
				<div
					style={{
						height: "100%",
						width: "100%",
						alignItems: "center",
						justifyContent: "center",
					}}>
					<View
						style={{
							width: "100%",
							padding: 10,

							justifyContent: "center",
							alignItems: "center",
							// maxWidth: 350,
							minWidth: 300,
							marginTop: 15,
							borderWidth: 1,
							borderColor: this.state.emailValid
								? "#E8758F"
								: "#1F2229",
							// backgroundColor: "#1F2229",
							backgroundColor: "#FFF",
							borderRadius: 12,
							flexDirection: "row",
						}}>
						<Image
							source={tick}
							style={{
								width: 20,
								height: 20,
								resizeMode: "contain",
								marginRight: 10,
								opacity: this.state.emailValid ? 1 : 0,
							}}
						/>

						<TextInput
							style={{
								width: "100%",
								fontSize: 16,
								textAlign: "left",
								color: "#FBFBFC",
								fontWeight: "200",
								fontFamily: "Gotham",
								letterSpacing: 0.26,
								padding: 5,
							}}
							placeholderTextColor={"#797979"}
							autoCapitalize='none'
							clearButtonMode='while-editing'
							autoCompleteType='username'
							autoCorrect={false}
							autoFocus={true}
							keyboardType='email-address'
							textContentType='emailAddress'
							placeholder={`Email address`}
							value={this.state.emailInput}
							onChangeText={(text) => {
								let validated = this.validateEmail(text);
								this.setState({
									emailInput: text,
								});
							}}
						/>
					</View>
				</div>

				<div
					style={{
						height: "100%",
						width: "100%",
						alignItems: "center",
						justifyContent: "center",
					}}>
					<View
						style={{
							width: "100%",
							padding: 10,

							justifyContent: "center",
							alignItems: "center",
							// maxWidth: 350,
							minWidth: 300,
							marginTop: 15,
							borderWidth: 1,
							borderColor:
								this.state.password.length > 3
									? "#E8758F"
									: "#1F2229",
							backgroundColor: "#FFF",
							borderRadius: 12,
							flexDirection: "row",
						}}>
						<Image
							source={tick}
							style={{
								width: 20,
								height: 20,
								resizeMode: "contain",
								marginRight: 10,
								opacity: this.state.password.length > 3 ? 1 : 0,
							}}
						/>
						<TextInput
							style={{
								width: "100%",
								fontSize: 16,
								textAlign: "left",
								color: "#FBFBFC",
								fontWeight: "200",
								fontFamily: "Gotham",
								letterSpacing: 0.26,
								padding: 5,
							}}
							placeholderTextColor={"#797979"}
							autoCapitalize='none'
							clearButtonMode='while-editing'
							autoCompleteType='password'
							secureTextEntry={true}
							textContentType='password'
							autoCorrect={false}
							placeholder={`Password`}
							value={this.state.password}
							// defaultValue={
							// 	this.state.password
							// }
							onChangeText={(text) => {
								this.setState({
									password: text,
								});
							}}
							onSubmitEditing={() => {
								// this.loginButton();
							}}
						/>
					</View>
				</div>

				<View
					style={{
						height: "100%",
						width: "100%",
						// alignItems: "center",
						// justifyContent: "center",
						marginTop: 15,
					}}>
					{!this.state.loading ? (
						<TouchableOpacity
							style={{
								width: "100%",
							}}
							disabled={this.state.loading}
							onPress={() => this.loginButton2()}>
							<View
								style={{
									backgroundColor:
										this.state.password.length > 6 &&
										this.state.emailValid
											? "#E8758F"
											: "#D7AAB4",
									width: "100%",
									padding: 14,
									justifyContent: "center",
									alignItems: "center",
									borderRadius: 10,
								}}>
								{this.state.loading ? (
									<ActivityIndicator />
								) : (
									<Text
										style={{
											color: "#FFF",
										}}>
										Login
									</Text>
								)}
							</View>
						</TouchableOpacity>
					) : (
						<View
							style={{
								backgroundColor: "#D7AAB4",
								padding: 6,
								justifyContent: "center",
								alignItems: "center",
								borderRadius: 15,
								width: 30,
								height: 30,
								marginLeft: "auto",
								marginRight: "auto",
							}}>
							<ActivityIndicator color='#FFF' />
						</View>
					)}
				</View>
			</View>
		);
	};

	render() {
		const defaultOptions = {
			loop: false,
			autoplay: true,
			animationData: animationData,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};

		let contain =
			this.state.screenWidth * 0.5 > this.state.screenHeight * 0.5
				? this.state.screenHeight * 0.5
				: this.state.screenWidth * 0.5;

		let maxContain = contain > 520 ? 520 : contain;

		let ar = 636 / 545;

		return (
			<View
				style={{
					flex: 1,
					// backgroundColor: APP_COLOURS.BG2,
					backgroundColor: APP_COLOURS.BG,
					height: "100%",
					overflow: "hidden",
				}}>
				<View
					style={{
						position: "absolute",
						right: 0,
						top: 0,
					}}>
					<AutoHeightImage
						width={window.innerWidth * 0.3}
						source={require("../assets/images/bg.png")}
					/>
				</View>

				<View
					style={{
						flex: 1,
						// width: 307,
						// height: "auto",
						alignItems: "center",
						justifyContent: "center",
						padding: 20,
						borderRadius: 25,
						maxWidth: 400,
						marginHorizontal: "auto",
					}}>
					<View
						style={{
							flex: 1,
							flexDirection: "row",
							alignItems: "center",
						}}>
						{/* <Image
							source={require("../assets/images/logo.png")}
							style={{
								width: 40,
								height: 40,
								resizeMode: "contain",
							}}
						/> */}
						<AutoHeightImage
							width={180}
							source={require("../assets/images/logo.png")}
						/>
						{/* <AutoHeightImage
							width={180}
							source={require("../assets/images/skrum_logo.png")}
						/> */}
					</View>

					{this.state.errorLogin ? (
						<View
							style={{
								width: 300,
								marginTop: 15,
								padding: 10,
							}}>
							<Text
								style={{
									color: "#FF5F5F",
									fontSize: 12,
									textAlign: "center",
								}}>
								We are unable to verify your profile, please
								check your email password and try again.
							</Text>
						</View>
					) : null}

					{this.state.showLoginForm ? (
						this.loginForm()
					) : (
						<View
							style={{
								flex: 2,
							}}
						/>
					)}
				</View>

				{this.state.screenWidth < 700 ? (
					<View
						style={{
							// width: this.state.screenWidth / 2,
							width: "100%",
							position: "absolute",
							bottom: 80,
							left: 0,
							// flexDirection: "row",
							// justifyContent: "space-between",
							justifyContent: "center",
							alignItems: "center",
							// paddingRight: 30,
							// paddingLeft: 30,
							// display: this.state.screenWidth < 700 ? "none" : "flex",
						}}>
						<View
							style={{
								width: "100%",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "center",
							}}>
							<Text
								style={{
									fontSize: 16,
									color: "#FFF",
								}}>
								<Font family='Poppins' weight={800}>
									tetrice
								</Font>
							</Text>
						</View>
					</View>
				) : (
					<View
						style={{
							// width: this.state.screenWidth / 2,
							width: "100%",
							position: "absolute",
							bottom: 30,
							left: 0,
							flexDirection: "row",
							justifyContent: "space-between",
							paddingRight: 30,
							paddingLeft: 30,
							alignItems: "center",
							// display: this.state.screenWidth < 700 ? "none" : "flex",
						}}>
						<Text
							style={{
								width: 110,
								fontSize: 16,
								color: "#313355",
							}}>
							<Font family='Poppins' weight={800}>
								tetrice
							</Font>
						</Text>
						<View
							style={{
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "center",
							}}>
							<Image
								source={require("./app_store.png")}
								style={{
									width: 130,
									height: 32,
									resizeMode: "contain",
								}}
							/>
							<Image
								source={require("./google_play.png")}
								style={{
									width: 130,
									height: 32,
									resizeMode: "contain",
									marginRight: 10,
								}}
							/>
						</View>
						<Text
							style={{
								width: 120,
								fontSize: 13,
								color: "#313355",
							}}>
							<Font family='Poppins' weight={300}>
								info@tetrice.co.za
							</Font>
						</Text>
					</View>
				)}
			</View>
		);
	}
}

export const withNavigation = (Component: Component) => {
	return (props) => <Component {...props} navigate={useNavigate()} />;
};

export default withNavigation(index);
