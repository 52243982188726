import React, { Component } from "react";

import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	Modal,
	TextInput,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
import { APP_COLOURS } from "../../APP_VARS";
import Fade from "react-reveal/Fade";
import AutoHeightImage from "../../Components/AutoHeightImageComp";
import Lottie from "react-lottie";
import * as animationData from "./sending.json";

export default class SendingFeedback extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<View
				style={{
					// backgroundColor: "#000",
					backgroundColor: APP_COLOURS.BG,
					width: window.innerWidth,
					overflow: "hidden",
					height: window.innerHeight,
					alignItems: "center",
				}}>
				{/* <View
					style={{
						flexDirection: "row",
						alignItems: "center",
						// position: "absolute",
						// left: 68,
						// top: 100,
						// opacity: 0.7,
					}}>
					<AutoHeightImage
						width={12}
						source={require("./verified.png")}
					/>
					<Text
						adjustsFontSizeToFit
						style={{
							fontWeight: "500",
							fontFamily: "Gotham",
							color: "#000",
							fontSize: 15,
							marginLeft: 4,
							// opacity: 0.7,
						}}>
						verified
					</Text>
				</View> */}

				{/* <View
					style={{
						position: "absolute",
						right: 0,
						top: -10,
					}}>
					<AutoHeightImage
						// width={window.innerWidth * 0.5}
						width={200}
						source={require("../../assets/images/bg.png")}
					/>
				</View> */}

				{/* <View
					style={{
						// flex: 1,
						flexDirection: "row",
						alignItems: "center",
						position: "absolute",
						left: 20,
						top: -10,
					}}>
					<AutoHeightImage
						width={180}
						source={require("../../assets/images/logo.png")}
					/>
				</View> */}

				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						padding: 5,
					}}>
					{/* <AutoHeightImage
						width={45}
						source={require("../../assets/images/menu.png")}
					/> */}

					<AutoHeightImage
						width={180}
						source={require("../../assets/images/logo.png")}
					/>
					<Text
						style={{
							color: "#000",
							fontSize: 19,
							fontWeight: "800",
							fontFamily: "Gotham",
							opacity: 0.8,
						}}>
						Sending...
					</Text>
					<Fade left>
						<View style={{}}>
							<Lottie
								options={{
									loop: true,
									autoplay: true,
									animationData: animationData,
									rendererSettings: {
										preserveAspectRatio: "xMidYMid slice",
									},
								}}
								height={90}
								width={90}
							/>
						</View>
					</Fade>
				</View>
			</View>
		);
	}
}
