import moment from "moment";
import React, { Component } from "react";
import {
	StyleSheet,
	View,
	Text,
	Image,
	TouchableOpacity,
	TextInput,
} from "react-native";

import starFill from "./starFill.png";
import star from "./star.png";
import AutoHeightImage from "../../Components/AutoHeightImageComp";
import { Fade } from "react-reveal";
import { APP_COLOURS } from "../../APP_VARS";

class SurveyItem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLiked: false,

			commentLines: 1,

			id: this.props.id,
			surveyDate: moment().toISOString(),

			currentQuestionIndex: 0,
		};
	}

	toggleLike = () => {
		this.setState((prevState) => ({
			isLiked: !prevState.isLiked,
			likeCount: prevState.isLiked
				? prevState.likeCount - 1
				: prevState.likeCount + 1,
		}));
	};

	toggleApproval = (item, text) => {
		this.setState((prevState) => ({
			[item]: text,
		}));
	};

	handleNext = () => {
		this.setState((prevState) => ({
			currentQuestionIndex: prevState.currentQuestionIndex + 1,
		}));
	};

	handleSaveAndSend = () => {
		console.log(this.state);
		this.props.handleApproval(this.state);
	};

	render() {
		const {
			isLiked,
			likeCount,
			commentCount,

			commentLines,
			approvedDate,
			commentAdded,
			approvePost,
			approvedCopy,
			approvedTags,
		} = this.state;
		const {
			username,
			avatarUri,
			method,
			postImageUri,
			verified,
			platform,
			copy,
			tags,
			postDate,
			screenWidth,
		} = this.props; // pass these props to display specific post data

		const { questions } = this.props;
		const { currentQuestionIndex } = this.state;
		const isLastQuestion = currentQuestionIndex === questions.length - 1;

		return (
			<View
				style={{
					// borderRadius: 15,
					// overflow: "hidden",
					backgroundColor: "#000",
					// marginBottom: 20,
					flex: 1,
					// justifyContent: "center",
					// alignItems: "center",
					padding: 10,
				}}>
				{/* <View
					style={{
						padding: 10,
						alignItems: "center",
						marginVertical: 5,
						flexDirection: "row",
						justifyContent: "space-between",
					}}>
					<TouchableOpacity onPress={this.toggleLike}>
						<Image
							source={
								isLiked
									? require("./heart.png")
									: require("./heartblank.png")
							}
							style={{
								width: 30,
								height: 30,
								margin: 5,
								resizeMode: "contain",
							}}
						/>
					</TouchableOpacity>
					
				</View> */}

				{/* {console.log(this.props)} */}
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
					}}>
					{/* .filter((m, i) => i === currentQuestionIndex) */}
					{this.props.questions.map((d, i) => (
						<View
							style={{
								display:
									currentQuestionIndex === i
										? "flex"
										: "none",
							}}>
							<Fade bottom>
								<View
									style={{
										flex: 1,
										justifyContent: "center",
										alignItems: "center",
										flex: 1,
									}}>
									<View
										style={{
											justifyContent: "center",
											alignItems: "center",
											flex: 1,
										}}>
										<Text
											style={{
												fontSize: 19,
												color: "#FFF",
												fontFamily: "Gotham",
												fontWeight: "500",
												marginTop: 10,
												width: "100%",
												textAlign: "center",

												justifyContent: "center",
												alignItems: "center",
												flex: 1,
											}}>
											{d.question}
										</Text>
									</View>
									{d.type === "scale" ? (
										<View
											style={{
												// width: 250,
												width: "100%",
												flexDirection: "row",
												marginTop: 20,
												justifyContent: "center",
											}}>
											{[1, 2, 3, 4, 5, 6, 7].map(
												(priority) => (
													<TouchableOpacity
														key={priority}
														style={{
															marginRight: 5,
														}}
														onPress={async () => {
															await this.setState(
																{
																	[d.q_number]:
																		priority,
																},
															);

															await console.log(
																this.state,
															);
														}}>
														{this.state[
															d.q_number
														] >= priority ? (
															<AutoHeightImage
																width={35}
																style={{
																	marginRight: 5,
																}}
																source={
																	starFill
																}
															/>
														) : (
															<AutoHeightImage
																width={35}
																style={{
																	marginRight: 5,
																	opacity: 0.3,
																}}
																source={star}
															/>
														)}
													</TouchableOpacity>
												),
											)}
										</View>
									) : null}

									{d.type === "multiple_choice" ? (
										<View
											style={{
												width: "100%",
												marginTop: 20,
												justifyContent: "center",
											}}>
											{d.options.map((option, i) => (
												<TouchableOpacity
													key={i}
													style={{
														padding: 10,
														marginVertical: 5,
														borderRadius: 8,
														backgroundColor:
															this.state[
																d.q_number
															] === option
																? APP_COLOURS.WHITE_TRANSPARENT
																: APP_COLOURS.WHITE_TRANSPARENT2,
													}}
													onPress={async () => {
														await this.setState({
															[d.q_number]:
																option,
														});

														await console.log(
															this.state,
														);
													}}>
													<Text
														style={{
															fontSize: 19,
															color: "#FFF",
															fontFamily:
																"Montserrat",
															fontWeight: "400",

															textAlign: "left",
														}}>
														{option}
													</Text>
												</TouchableOpacity>
											))}
										</View>
									) : null}

									{d.type === "open_ended" ? (
										<View
											style={{
												width: "100%",
												marginTop: 20,
												justifyContent: "center",
											}}>
											<View style={{}}>
												<Text
													style={{
														fontWeight: "400",
														fontFamily:
															"Avenir Next",
														fontSize: 12,
														color: "#0098FD",
														marginBottom: 4,
														position: "absolute",
														top: -12,
														left: 9,
														zIndex: 99,
														backgroundColor: "#000",
														padding: 4,
														borderRadius: 8,
													}}>
													Answer
												</Text>

												<TextInput
													style={{
														fontSize: 16,
														color: "#FFF",
														fontWeight: "400",
														fontFamily:
															"Avenir Next",
														width: "100%",
														paddingLeft: 10,
														padding: 13,
														borderWidth: 1,
														borderColor: "#0098FD",
														borderRadius: 7,
														borderWidth: 1,
														minHeight: 30,
													}}
													placeholderTextColor={
														"#797979"
													}
													autoCapitalize='sentences'
													multiline
													numberOfLines={5}
													clearButtonMode='while-editing'
													// autoCompleteType="off"
													placeholder={`Type something`}
													onChangeText={(text) => {
														this.setState({
															[d.q_number]: text,
														});
													}}
												/>
											</View>
										</View>
									) : null}

									<Text
										style={{
											fontSize: 12,
											color: "#FFF",
											fontFamily: "Gotham",
											fontWeight: "300",
											marginTop: 10,

											textAlign: "center",
											width: "100%",
											justifyContent: "center",
											alignItems: "center",
											opacity: 0.5,
										}}>
										{d.description}
									</Text>
								</View>
							</Fade>
						</View>
					))}
				</View>
				{/* <View style={{}}>
						<Text
							style={{
								fontWeight: "400",
								fontFamily: "Gotham",
								fontSize: 16,
								color: "#FFF",
								marginTop: 20,
								backgroundColor: "#000",
								borderRadius: 8,
								width: "100%",
								textAlign: "center",
							}}>
							Post image/video
						</Text>

						<View
							style={{
								marginTop: 5,
								marginVertical: 5,
								flexDirection: "row",
							}}>
							<TouchableOpacity
								style={{
									flex: 1,
									backgroundColor:
										approvePost === "false"
											? "#FF4E24"
											: "transparent",
									borderWidth: 1,
									borderColor: "#FF4E24",
									padding: 15,
									borderRadius: 10,
									justifyContent: "center",
									alignItems: "center",
									marginVertical: 5,
									flexDirection: "row",
									marginRight: 5,
								}}
								onPress={() =>
									this.toggleApproval("approvePost", "false")
								}>
								<Text
									style={{
										fontSize: 16,
										marginTop: 2,
										fontWeight: "500",
										color: "#FFF",
									}}>
									Reject
								</Text>
							</TouchableOpacity>

							<TouchableOpacity
								style={{
									flex: 1,
									backgroundColor:
										approvePost === "true"
											? "#0098FD"
											: "transparent",
									borderWidth: 1,
									borderColor: "#0098FD",
									padding: 15,
									borderRadius: 10,
									justifyContent: "center",
									alignItems: "center",
									marginVertical: 5,
									flexDirection: "row",
								}}
								onPress={() =>
									this.toggleApproval("approvePost", "true")
								}>
								<Text
									style={{
										fontSize: 16,
										marginTop: 2,
										fontWeight: "500",
										color: "#FFF",
									}}>
									Approve
								</Text>
							</TouchableOpacity>
						</View>
					</View>

					<View style={{}}>
						<Text
							style={{
								fontWeight: "400",
								fontFamily: "Gotham",
								fontSize: 16,
								color: "#FFF",
								marginTop: 20,
								backgroundColor: "#000",
								borderRadius: 8,
								width: "100%",
								textAlign: "center",
							}}>
							Copy & Hash tags
						</Text>

						<View
							style={{
								marginTop: 5,
								marginVertical: 5,
								flexDirection: "row",
							}}>
							<TouchableOpacity
								style={{
									flex: 1,
									backgroundColor:
										approvedCopy === "false"
											? "#FF4E24"
											: "transparent",
									borderWidth: 1,
									borderColor: "#FF4E24",
									padding: 15,
									borderRadius: 10,
									justifyContent: "center",
									alignItems: "center",
									marginVertical: 5,
									flexDirection: "row",
									marginRight: 5,
								}}
								onPress={() =>
									this.toggleApproval("approvedCopy", "false")
								}>
								<Text
									style={{
										fontSize: 16,
										marginTop: 2,
										fontWeight: "500",
										color: "#FFF",
									}}>
									Reject
								</Text>
							</TouchableOpacity>

							<TouchableOpacity
								style={{
									flex: 1,
									backgroundColor:
										approvedCopy === "true"
											? "#0098FD"
											: "transparent",
									borderWidth: 1,
									borderColor: "#0098FD",
									padding: 15,
									borderRadius: 10,
									justifyContent: "center",
									alignItems: "center",
									marginVertical: 5,
									flexDirection: "row",
								}}
								onPress={() =>
									this.toggleApproval("approvedCopy", "true")
								}>
								<Text
									style={{
										fontSize: 16,
										marginTop: 2,
										fontWeight: "500",
										color: "#FFF",
									}}>
									Approve
								</Text>
							</TouchableOpacity>
						</View>
					</View>

					<View style={{}}>
						<Text
							style={{
								fontWeight: "400",
								fontFamily: "Gotham",
								fontSize: 16,
								color: "#FFF",
								marginTop: 20,
								backgroundColor: "#000",
								borderRadius: 8,
								width: "100%",
								textAlign: "center",
							}}>
							Mentions
						</Text>

						<View
							style={{
								marginTop: 5,
								marginVertical: 5,
								flexDirection: "row",
							}}>
							<TouchableOpacity
								style={{
									flex: 1,
									backgroundColor:
										approvedTags === "false"
											? "#FF4E24"
											: "transparent",
									borderWidth: 1,
									borderColor: "#FF4E24",
									padding: 15,
									borderRadius: 10,
									justifyContent: "center",
									alignItems: "center",
									marginVertical: 5,
									flexDirection: "row",
									marginRight: 5,
								}}
								onPress={() =>
									this.toggleApproval("approvedTags", "false")
								}>
								<Text
									style={{
										fontSize: 16,
										marginTop: 2,
										fontWeight: "500",
										color: "#FFF",
									}}>
									Reject
								</Text>
							</TouchableOpacity>

							<TouchableOpacity
								style={{
									flex: 1,
									backgroundColor:
										approvedTags === "true"
											? "#0098FD"
											: "transparent",
									borderWidth: 1,
									borderColor: "#0098FD",
									padding: 15,
									borderRadius: 10,
									justifyContent: "center",
									alignItems: "center",
									marginVertical: 5,
									flexDirection: "row",
								}}
								onPress={() =>
									this.toggleApproval("approvedTags", "true")
								}>
								<Text
									style={{
										fontSize: 16,
										marginTop: 2,
										fontWeight: "500",
										color: "#FFF",
									}}>
									Approve
								</Text>
							</TouchableOpacity>
						</View>
					</View>

					<View style={{}}>
						<Text
							style={{
								fontWeight: "400",
								fontFamily: "Gotham",
								fontSize: 16,
								color: "#FFF",
								marginTop: 20,
								backgroundColor: "#000",
								borderRadius: 8,
								width: "100%",
								textAlign: "center",
							}}>
							Date of post
						</Text>

						<View
							style={{
								marginTop: 5,
								marginVertical: 5,
								flexDirection: "row",
							}}>
							<TouchableOpacity
								style={{
									flex: 1,
									backgroundColor:
										approvedDate === "false"
											? "#FF4E24"
											: "transparent",
									borderWidth: 1,
									borderColor: "#FF4E24",
									padding: 15,
									borderRadius: 10,
									justifyContent: "center",
									alignItems: "center",
									marginVertical: 5,
									flexDirection: "row",
									marginRight: 5,
								}}
								onPress={() =>
									this.toggleApproval("approvedDate", "false")
								}>
								<Text
									style={{
										fontSize: 16,
										marginTop: 2,
										fontWeight: "500",
										color: "#FFF",
									}}>
									Reject
								</Text>
							</TouchableOpacity>

							<TouchableOpacity
								style={{
									flex: 1,
									backgroundColor:
										approvedDate === "true"
											? "#0098FD"
											: "transparent",
									borderWidth: 1,
									borderColor: "#0098FD",
									padding: 15,
									borderRadius: 10,
									justifyContent: "center",
									alignItems: "center",
									marginVertical: 5,
									flexDirection: "row",
								}}
								onPress={() =>
									this.toggleApproval("approvedDate", "true")
								}>
								<Text
									style={{
										fontSize: 16,
										marginTop: 2,
										fontWeight: "500",
										color: "#FFF",
									}}>
									Approve
								</Text>
							</TouchableOpacity>
						</View>
					</View> */}

				{/* <View style={{ flexDirection: "row" }}>
						<View
							style={{
								width: 35,
								height: 35,
								borderWidth: 1,
							}}>
							<Image
								source={require("./verified.png")}
								style={{
									width: 15,
									height: 15,
									resizeMode: "contain",
								}}
							/>
						</View>
					</View> */}

				{!isLastQuestion ? (
					<TouchableOpacity
						style={{
							borderRadius: 10,
							justifyContent: "center",
							alignItems: "center",
							marginVertical: 25,
							flexDirection: "row",
							overflow: "hidden",
							opacity:
								typeof this.state[
									questions[currentQuestionIndex].q_number
								] !== "undefined"
									? 1
									: 0,
						}}
						onPress={() => {
							// this.props.handleApproval(this.state);
							this.handleNext();
						}}>
						<Image
							source={require("./button.svg").default}
							style={{
								width: "101%",
								height: 45,
								resizeMode: "cover",
							}}
						/>

						<Text
							style={{
								fontSize: 16,
								marginTop: 2,
								fontWeight: "600",
								color: "#FFF",
								position: "absolute",
							}}>
							Next
						</Text>
					</TouchableOpacity>
				) : (
					<View>
						{/* <View style={{ width: "100%", opacity: 0.6 }}>
							<View
								style={{
									marginTop: 20,
									marginVertical: 5,
								}}>
								<Text
									style={{
										fontWeight: "400",
										fontFamily: "Gotham",
										fontSize: 12,
										color: "#0098FD",
										marginBottom: 4,
										position: "absolute",
										top: -12,
										left: 9,
										zIndex: 99,
										backgroundColor: "#000",
										padding: 4,
										borderRadius: 8,
									}}>
									Add a comment
								</Text>

								<TextInput
									style={{
										fontSize: 16,
										color: "#FFF",
										fontWeight: "400",
										fontFamily: "Gotham",
										width: "100%",
										paddingLeft: 10,
										padding: 13,
										borderWidth: 1,
										borderColor: "#0098FD",
										borderRadius: 7,
										borderWidth: 1,
										minHeight: 30,
									}}
									placeholderTextColor={"#797979"}
									autoCapitalize='sentences'
									multiline
									numberOfLines={commentLines}
									clearButtonMode='while-editing'
									// autoCompleteType="off"
									placeholder={`Type something`}
									onChangeText={(text) => {
										let commentLines = text.length / 50;
										console.log();
										commentLines = commentLines + 1;
										this.setState({
											comment: text,
											commentLines:
												commentLines.toFixed(0),
										});
									}}
								/>
							</View>
						</View> */}
						<TouchableOpacity
							style={{
								borderRadius: 10,
								justifyContent: "center",
								alignItems: "center",
								marginVertical: 25,
								flexDirection: "row",
								overflow: "hidden",
							}}
							onPress={() => {
								this.handleSaveAndSend();
							}}>
							<Image
								source={require("./button.svg").default}
								style={{
									width: "101%",
									height: 45,
									resizeMode: "cover",
								}}
							/>

							<Text
								style={{
									fontSize: 16,
									marginTop: 2,
									fontWeight: "600",
									color: "#FFF",
									position: "absolute",
								}}>
								Save & Send
							</Text>
						</TouchableOpacity>
					</View>
				)}
			</View>
		);
	}
}

const styles = StyleSheet.create({
	username: {},
	postImage: {
		width: "100%",
		height: 400, // set a fixed height for the image
	},
});

export default SurveyItem;
