import React, { Component } from "react";

import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	Modal,
	TextInput,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
import { APP_COLOURS } from "../../APP_VARS";
import Lottie from "react-lottie";
import * as animationData from "./sending.json";
import AutoHeightImage from "../../Components/AutoHeightImageComp";
import LottieControl from "./LottieControl";

export default class FeedBackSent extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<View
				style={{
					// backgroundColor: "#000",
					backgroundColor: APP_COLOURS.BG,
					width: window.innerWidth,
					overflow: "hidden",
					height: window.innerHeight,
					alignItems: "center",
				}}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						padding: 5,
					}}>
					{/* <View
						style={{
							flexDirection: "row",
							alignItems: "center",
							// position: "absolute",
							// left: 68,
							// top: 100,
							// opacity: 0.7,
						}}>
						<AutoHeightImage
							width={16}
							source={require("./verified.png")}
						/>
						<Text
							adjustsFontSizeToFit
							style={{
								fontWeight: "500",
								fontFamily: "Gotham",
								color: "#FFF",
								fontSize: 16,
								marginLeft: 4,
								// opacity: 0.7,
							}}>
							verified
						</Text>
					</View> */}
					<AutoHeightImage
						width={180}
						source={require("../../assets/images/logo.png")}
					/>
					<Text
						style={{
							fontSize: 14,
							color: "#000",
							fontWeight: "300",
							padding: 2,
							opacity: 0.7,
							marginBottom: 20,
						}}>
						Thank you for your submission
					</Text>

					<View style={{}}>
						{/* <Lottie
							options={{
								loop: false,
								autoplay: true,
								animationData: animationData,
								rendererSettings: {
									preserveAspectRatio: "xMidYMid slice",
								},
							}}
							height={90}
							width={90}
						/> */}
						<LottieControl animationData={animationData} />
					</View>
				</View>
			</View>
		);
	}
}
